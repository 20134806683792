@import "../../node_modules/bootstrap/scss/_functions";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import "../../node_modules/bootstrap/scss/variables";

// Custom SCSS

//HEADER
div[id^='navbarStandard'] .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
 }

//FOOTER
div[id^='footer-icons'] a:hover {
    background: var(--falcon-primary) !important;
    color: var(--falcon-light) !important;
    
}

/*-----------------------------------------------
|   Landing banner style
-----------------------------------------------*/
.img-landing-banner, .img-showcase-banner {
  -webkit-transform: translateY(-1rem);
  -ms-transform: translateY(-1rem);
  transform: translateY(-1rem);
  margin-bottom: -10rem;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  position: relative;
  -webkit-box-shadow: var(--falcon-box-shadow-lg);
  box-shadow: var(--falcon-box-shadow-lg);
  display: block;
}
@media (min-width: 1540px) and (max-width: 10000px) {
  .img-landing-banner, .img-showcase-banner {
    margin-bottom: -20rem;
  }
}
@media (min-width: 1200px) and (max-width: 1539.98px) {
  .img-landing-banner, .img-showcase-banner {
    margin-bottom: -14rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .img-landing-banner, .img-showcase-banner {
    margin-bottom: -10rem;
  }
}
.img-landing-banner.img-showcase-banner:after, .img-showcase-banner.img-showcase-banner:after {
  display: none;
}
.img-landing-banner:after, .img-showcase-banner:after {
  content: "";
  position: absolute;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.8)));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70%;
  -webkit-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}
.img-landing-banner:hover, .img-landing-banner:focus, .img-showcase-banner:hover, .img-showcase-banner:focus {
  -webkit-transform: translateY(-6rem);
  -ms-transform: translateY(-6rem);
  transform: translateY(-6rem);
}
.img-landing-banner:hover:after, .img-landing-banner:focus:after, .img-showcase-banner:hover:after, .img-showcase-banner:focus:after {
  opacity: 0;
}

@media (max-width: 575.98px) {
  .landing-cta-img {
    width: 100%;
    height: auto;
  }
}
